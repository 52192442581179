/* developmental */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
#ocialtcontain {
  .reportContainer {
    font-family: 'Noto Sans';
    font-size: 12pt;
    color: #111111;
  }
  .reportContainer.reportContainer,
  .reportContainer #downloadReport.reportContainer {
    background-color: transparent !important;
  }
  .reportContainer .pdfSections {
    width: 960px !important;
    max-width: 960px !important;
    margin: 20px auto;
    padding: 30px 118px;
    height: 1357px !important;
    position: relative;
  }
  .reportContainer .box-shadow-no-export {
    box-shadow: 1px 2px 4px #c0c0c0;
  }
  .reportContainer .page-header {
    width: 100%;
    background: none !important;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .reportContainer .page-header .logo {
    width: 151px;
    margin-right: 10px;
  }
  .reportContainer .page-header span {
    font-size: 11pt;
  }
  .reportContainer .page-footer {
    width: 100%;
    background: none;
    height: 68px;
    position: absolute;
    bottom: 0px !important;
  }
  .reportContainer .cover {
    /* margin: 10px auto!important; */
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .reportContainer .cover .container {
    width: 960px;
    height: 1353px;
    margin: 0 !important;
    padding: 28px;
    /* margin-top: 30px; */
    /* margin-left: 30px; */
    display: flex !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .reportContainer .cover .container .content {
    display: flex !important;
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    /* width: 575px; */
    overflow: hidden;
    padding-right: 100px;
  }
  .reportContainer .cover .container .banner {
    width: 220px;
    height: 1290px !important;
    image-rendering: crisp-edges;
    border-bottom: 1px white solid;
    box-sizing: border-box;
    object-fit: cover;
    /* overflow: hidden; */
  }
  .reportContainer .cover .container .content img {
    width: 185px;
  }
  .reportContainer .cover .container .content .section {
    height: 33.32%;
    width: 100%;
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .reportContainer .cover .container .content .section:nth-of-type(1) {
    height: 33.32%;
    width: 100%;
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 100px;
  }
  .reportContainer .cover .container .content .section:nth-of-type(3) {
    height: 33.32%;
    width: 100%;
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 100px;
  }
  .reportContainer .cover .container .content .divider {
    border-top: solid #cccccc 1px;
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .reportContainer .cover .container .content h1 {
    font-family: 'Noto Sans';
    color: #202e43;
    font-size: 52px !important;
    font-weight: 700;
    line-height: 80px !important;
    text-align: left;
    padding: 0px !important;
    margin: 0px 0px 0px 20px !important;
  }
  .reportContainer .cover .container .content h2 {
    font-family: 'Noto Sans';
    color: #202e43;
    font-size: 24px !important;
    font-weight: normal !important;
    line-height: 32px !important;
    text-align: left;
    margin: 0px 0px 0px 20px !important;
  }
  .reportContainer .cover .container .content h3 {
    display: block;
    text-align: left;
    width: 100%;
    color: #25282b;
    font-weight: normal !important;
    font-size: 20px;
    line-height: 30px;
    margin: 0px 0px 0px 20px !important;
  }
  .reportContainer .text-block {
    border-top: 2px #cccccc solid;
    padding-top: 18px;
    padding-bottom: 24px;
    margin-bottom: 5px;
    color: #111111;
    text-align: left;
    font-size: 12pt;
    line-height: 22pt;
  }
  .reportContainer .text-block-bottom {
    margin-top: 24px;
    border-bottom: 2px #cccccc solid;
  }
  .reportContainer .text-block h4 {
    font-size: 28px;
    line-height: 32pt;
    font-weight: 700 !important;
    width: 95%;
    padding-bottom: 24px !important;
  }
  .reportContainer .text-block h5 {
    font-size: 14pt;
    font-weight: 600;
  }
  .reportContainer .text-block p {
    font-size: 12pt;
    line-height: 23pt;
    width: 95%;
    margin-bottom: 20px auto;
    color: #111111;
  }
  .reportContainer .fw-stack {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .reportContainer .dimension-definition {
    width: 100%;
    margin-top: 22px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .reportContainer .dimension-definition .icon {
    width: 58px !important;
    height: 58px !important;
    border-radius: 8px;
    filter: none !important;
    display: flex;
    flex-shrink: 0;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-size: 34px;
  }
  .reportContainer .dimension-definition .text {
    margin-top: 4px;
    font-size: 12pt;
    color: #111111;
  }
  .reportContainer .dimension-definition .text .name {
    font-weight: 700;
  }
  .reportContainer .dimension-meters {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 4px;
  }
  .reportContainer .dimension-meters .bar-contain {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 12px;
    margin-top: 16px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .reportContainer .dimension-meters .bar-contain .bar {
    width: 100% !important;
    height: 38px;
    position: relative;
    /* display: flex;
   align-items: center;
   justify-content: flex-start; */
    border-radius: 8px;
    border: 1px #7a8d98 solid;
    background: #d9d9d9;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0px !important;
  }
  .reportContainer .dimension-meters .bar-contain .bar-inner {
    height: 36px;
    border-radius: 0px 8px 8px 0px;
    z-index: 2;
    position: absolute;
    left: 0px;
    top: 0px;
    /* width: 50%; */
    /* flex-shrink: 0;
   flex-grow: 0; */
    /* border-radius: 4px 0px 0px 4px; */
  }
  .reportContainer .dimension-meters .bar-contain .norm-marker {
    height: 100%;
    border-right: 3px #ffffff solid;
    z-index: 5;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .reportContainer .dimension-meters .text-contain {
    margin-top: 16px;
    display: block;
  }
  .reportContainer .dimension-meters .text-contain .icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px !important;
    /* border: 1px #dfdfdf solid; */
    filter: none !important;
    color: #fff;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
  .reportContainer .dimension-meters .text-contain .label {
    font-weight: 700;
    margin-right: 8px;
    display: inline;
  }
  .reportContainer .dimension-meters .text-contain .description {
    text-align: left;
    padding-bottom: 1px;
    padding-top: 1px;
  }
  /* table of contents */
  .reportContainer .table-of-contents {
    width: 100%;
  }
  .reportContainer .table-of-contents .toc-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16pt;
    line-height: 26pt;
  }
  .reportContainer .table-of-contents .toc-row .col-left {
    font-weight: 700;
  }
  .reportContainer .dimension-panes {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .reportContainer .dimension-panes .pane {
    width: 172px;
    height: 172px;
    border-radius: 8px;
    margin: 4px;
  }
  .reportContainer .dimension-panes .pane .header {
    border-bottom: 1px solid #ffffff;
    width: 90%;
    margin: 4px auto;
    height: 48px;
    padding: 12px;
  }
  .reportContainer .dimension-panes .pane .header h5 {
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    margin: 16px auto;
    margin-bottom: 16px;
    margin-top: 8px;
  }
  .reportContainer .dimension-panes .pane .list {
    width: 80%;
    margin: 4px auto;
    font-size: 9pt;
    line-height: 11pt;
  }
  .reportContainer .dimension-panes .pane ul li {
    text-align: center;
    margin-top: 6px;
    color: #ffffff;
  }
  /* dimension high/low definition table */
  .reportContainer .definition-table {
    width: 100%;
    margin-top: -40px;
  }
  .reportContainer .definition-table .definition-header,
  .reportContainer .definition-body .table-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .reportContainer .definition-table .definition-header {
    border-bottom: #cccccc 3px solid;
  }
  .reportContainer .definition-table .definition-header .center span,
  .reportContainer .definition-table .definition-header .right span {
    text-align: left;
    margin-left: -108px;
  }
  .reportContainer .definition-table .left {
    width: 20%;
    font-size: 10pt;
  }
  .reportContainer .definition-table .definition-body .left span {
    font-weight: 600;
    font-size: 13pt;
  }
  .reportContainer .definition-table .center,
  .reportContainer .definition-table .right {
    width: 40%;
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .reportContainer .definition-table .definition-body .center div,
  .reportContainer .definition-table .definition-body .right div {
    max-height: 118px;
    min-height: 95px;
    width: 96%;
    padding-top: 4px;
    font-size: 15px;
  }
  .reportContainer .definition-table .definition-body .pseudo-borders {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }
  .reportContainer .definition-table .definition-body .pseudo-borders div {
    width: 40%;
    height: 3px !important;
    padding: 4px;
  }
  .reportContainer .definition-table .definition-body .pseudo-borders div div {
    width: 98%;
    border-bottom: 3px #cccccc solid;
  }
  /*  dimension breakdown  */
  .reportContainer .dimension-breakdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 18px;
  }
  .reportContainer .dimension-breakdown .dimension-header {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .reportContainer .dimension-breakdown .dimension-header .left {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .reportContainer .dimension-breakdown .dimension-header .icon {
    width: 58px;
    min-width: 58px;
    height: 58px;
    filter: none !important;
    border-radius: 8px;
    color: #fff;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex-basis: 0 !important;
  }
  .reportContainer .dimension-breakdown .dimension-header .dimension-title,
  .reportContainer .dimension-breakdown .dimension-header .dimension-level {
    font-weight: 700;
  }
  .reportContainer .dimension-breakdown .dimension-competencies {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .reportContainer .dimension-breakdown .dimension-competencies .competency {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .reportContainer
    .dimension-breakdown
    .dimension-competencies
    .competency
    .competency-title {
    font-weight: 700;
    width: 250px;
  }
  .reportContainer
    .dimension-breakdown
    .dimension-competencies
    .competency
    .competency-bar {
    height: 16px;
    /* display: flex;
   align-items: center;
   justify-content: flex-start; */
    position: relative;
    border: 1px #7a8d98 solid;
    background: #d9d9d9;
    border-radius: 8px;
    box-sizing: border-box;
    width: 360px;
    overflow: hidden;
    margin-left: 15px;
  }
  .reportContainer
    .dimension-breakdown
    .dimension-competencies
    .competency
    .competency-bar
    .competency-bar-inner {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 14px;
    border-radius: 0px 8px 8px 0px;
  }
  .reportContainer
    .dimension-breakdown
    .dimension-competencies
    .competency
    .competency-bar
    .norm-marker {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    border-right: 4px #ffffff solid;
  }
  .reportContainer
    .dimension-breakdown
    .dimension-competencies
    .competency
    .competency-level {
    width: 160px;
    text-align: right;
  }
  .reportContainer .competency-highlight {
    width: 100%;
    border-top: 2px solid #c1c1c1;
    display: flex;
    flex-direction: column;
  }
  .reportContainer .competency-highlight .competency-highlight-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 26px;
    margin-bottom: 26px;
  }
  .reportContainer .competency-highlight .competency-highlight-header .icon {
    width: 58px;
    height: 58px;
    font-size: 32px;
    color: #fff;
    border: 0px;
    border-radius: 8px;
    filter: none !important;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-header
    .baseline-align {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 700px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-header
    .competency-highlight-title {
    font-weight: 700;
    width: 180px !important;
    max-width: 180px;
    min-width: 180px;
    font-size: 22px;
    line-height: 24px;
    margin-right: 30px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-header
    .competency-highlight-bar {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    flex-grow: 0;
    height: 16px;
    flex-shrink: 0;
    border: 1px #7a8d98 solid;
    border-radius: 8px;
    /* display: flex;
   align-items: center;
   justify-content: flex-start; */
    position: relative;
    overflow: hidden;
    background: #d9d9d9;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-header
    .competency-highlight-bar
    .competency-highlight-bar-inner {
    height: 14px;
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    opacity: 0.8;
    left: 0px;
    top: 0px;
    z-index: 2;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-header
    .competency-highlight-bar
    .norm-marker {
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border-right: 3px solid #ffffff;
    z-index: 5;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-header
    .competency-highlight-level {
    text-align: right;
    width: 220px;
    font-weight: 700;
  }
  .reportContainer .competency-highlight .competency-highlight-description {
    width: 100%;
  }
  .reportContainer .competency-highlight .competency-highlight-description p {
    font-size: 12pt;
    line-height: 23pt;
  }
  .reportContainer .competency-highlight .competency-highlight-subcompetencies {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-subcompetencies
    h4 {
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-subcompetencies
    .subcomp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-subcompetencies
    .subcomp
    .subcomp-title {
    font-weight: 700;
    font-size: 16px;
    width: 200px;
    margin-right: 20px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-subcompetencies
    .subcomp
    .subcomp-bar {
    width: 200px;
    height: 16px;
    border: 1px solid #7a8d98;
    border-radius: 8px;
    /* display: flex;
   align-items: center;
   justify-content: flex-start; */
    position: relative;
    overflow: hidden;
    justify-self: flex-start;
    background: #d9d9d9;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-subcompetencies
    .subcomp
    .subcomp-bar
    .subcomp-bar-inner {
    height: 14px;
    opacity: 0.8;
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-subcompetencies
    .subcomp
    .subcomp-bar
    .norm-marker {
    border-right: 3.2px #fff solid;
    border-radius: 0px;
    height: 100%;
    z-index: 5;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .reportContainer
    .competency-highlight
    .competency-highlight-subcompetencies
    .subcomp
    .subcomp-level {
    width: 400px;
    margin-left: 20px;
    font-size: 16px;
    text-align: right;
  }
  .reportContainer .competency-highlight .coaching-lists {
    margin-top: 30px;
    border-top: 2px #c1c1c1 solid;
  }
  .reportContainer .competency-highlight p {
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .reportContainer .competency-highlight .coaching-lists .coaching-list {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .reportContainer .competency-highlight .coaching-lists .coaching-list .title {
    font-weight: 700;
    margin-bottom: 8px;
  }
  .reportContainer .competency-highlight .coaching-lists .coaching-list span {
    margin-bottom: 8px;
  }
  .reportContainer .competency-highlight .coaching-lists .coaching-list ul {
    margin-top: 10px;
    margin-left: 28px;
  }
  .reportContainer .competency-highlight .coaching-lists .coaching-list ul li {
    list-style-type: disc;
    margin-bottom: 8px;
    font-size: 12pt;
    line-height: 23pt;
  }
  /* plan matrix */
  .reportContainer .plan-matrix {
    width: 100%;
    border-radius: 8px;
    border: 1px #cccccc solid;
    margin-top: 32px;
  }
  .reportContainer .plan-matrix .plan-matrix-header {
    background: #45aae1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
  }
  .reportContainer .plan-matrix-body .row {
    border-bottom: 3px solid #cccccc;
    padding: 0px;
    text-align: left;
  }
  .reportContainer .plan-matrix-body .row:last-of-type {
    border-bottom: 0px;
  }
  .reportContainer .plan-matrix-header .left,
  .reportContainer .plan-matrix-body .left {
    width: 26%;
  }
  .reportContainer
    .plan-matrix-body
    .reportContainer
    .plan-matrix-header
    .center,
  .reportContainer .plan-matrix-body .center {
    width: 48%;
    padding: 16px;
  }
  .reportContainer .plan-matrix-body .center {
    border-right: 3px #cccccc solid;
    border-left: 3px #cccccc solid;
  }
  .reportContainer .plan-matrix-header .right,
  .reportContainer .plan-matrix-body .right {
    width: 26%;
  }
  .reportContainer .plan-matrix-header .left span,
  .reportContainer .plan-matrix-header .center span,
  .reportContainer .plan-matrix-header .right span {
    color: #ffffff;
    font-size: 12pt;
    font-weight: 600;
    text-align: center;
    margin: 8px;
  }
  .reportContainer .plan-matrix-body ul li {
    list-style-type: disc;
    margin-left: 40px;
    margin-bottom: 40px;
  }
  .reportContainer .plan-matrix-body ul li,
  .reportContainer .plan-matrix-body ol li {
    margin-top: 16px;
  }
  /*  competency overview vertical chart */
  .reportContainer .competency-overview {
    margin-top: 10px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }
  .reportContainer .competency-overview .competency-overview-key {
    width: 100%;
    border-bottom: 2px solid #cccccc;
    border-top: 2px solid #cccccc;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px;
    flex-wrap: wrap;
    margin-top: -40px;
  }
  .reportContainer .competency-overview .competency-overview-key .item {
    width: 24.4%;
    color: #7a8d98;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    font-size: 11px;
  }
  .reportContainer .competency-overview .competency-overview-key .item .bullet {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: 2 2 2px #ccc;
    margin-right: 4px;
  }
  .reportContainer .competency-overview .competency-overview-chart-contain {
    width: 100%;
    margin-top: 8px;
    height: inherit;
    position: relative;
  }
  .reportContainer
    .competency-overview-chart-contain
    .competency-overview-chart-lines {
    z-index: 5;
    width: 100%;
    height: 880px;
    position: absolute;
    left: 0px;
    top: 20px;
  }
  .reportContainer
    .competency-overview-chart-contain
    .competency-overview-chart-inner {
    z-index: 6;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 10px;
    padding-top: 10px;
  }
  .reportContainer
    .competency-overview-chart-contain
    .competency-overview-chart-inner
    .row {
    width: 100%;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .reportContainer
    .competency-overview-chart-contain
    .competency-overview-chart-inner
    .row
    .label {
    width: 27%;
    font-weight: 600;
    font-size: 9pt;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .reportContainer
    .competency-overview-chart-contain
    .competency-overview-chart-inner
    .row
    .bar-contain {
    width: 63.5%;
    height: 20px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-right: 0px;
    padding-left: 2px;
    justify-content: flex-start;
  }
  .reportContainer
    .competency-overview-chart-contain
    .competency-overview-chart-inner
    .row
    .bar-contain
    .bar-inner {
    height: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 10%;
  }
  .reportContainer
    .competency-overview-chart-contain
    .competency-overview-chart-inner
    .row
    .level {
    width: 20%;
    text-align: right;
    font-size: 10pt;
    color: #111111;
  }
  .reportContainer .competency-overview-chart-contain .legend-contain {
    width: 58%;
    margin-left: 27%;
    margin-right: 10%;
    height: 20px;
    display: flex;
    flex-direction: row;
    background: #fff;
    z-index: 6;
    padding-bottom: 8px;
    font-size: 10px;
  }
  .reportContainer .competency-overview-chart-contain .legend-contain .label {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #ccc;
  }
  .reportContainer .competency-overview-chart-lines .lines-contain {
    width: 50%;
    margin-left: 28%;
    margin-right: 30%;
    display: flex;
    flex-direction: row;
    height: 100%;
    border-right: 3px #ccc solid;
  }
  .reportContainer .lines-contain .line {
    width: 20%;
    border-left: 3px solid #ccc;
    height: 100%;
  }
  .reportContainer .competency-overview .footnote {
    font-style: italic;
    color: #262626;
    position: absolute;
    bottom: 80px;
    left: 132px;
    font-size: 12px;
  }
  /* key competencies */
  .reportContainer .key-competency {
    margin-top: 32px;
  }
  .reportContainer .key-competency h5 {
    font-size: 14pt;
  }
  .reportContainer .key-competency .key-competency-inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
  }
  .reportContainer .key-competency .key-competency-inner .competency-stack {
    width: 49%;
    border-radius: 8px;
    background: #ffffff;
  }
  .reportContainer
    .key-competency
    .key-competency-inner
    .competency-stack
    .competency-chip {
    height: 106px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: #cccccc 1px solid;
  }
  .reportContainer
    .key-competency
    .key-competency-inner
    .competency-stack
    .competency-chip:first-of-type {
    border-bottom: 1px solid #cccccc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .reportContainer
    .key-competency
    .key-competency-inner
    .competency-stack
    .competency-chip:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .reportContainer
    .key-competency
    .key-competency-inner
    .competency-stack
    .competency-chip
    .color-block {
    min-width: 60px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    height: 100%;
  }
  .reportContainer
    .key-competency
    .key-competency-inner
    .competency-stack
    .competency-chip:first-of-type
    .color-block {
    border-bottom-left-radius: 0px;
  }
  .reportContainer
    .key-competency
    .key-competency-inner
    .competency-stack
    .competency-chip:last-of-type
    .color-block {
    border-top-left-radius: 0px;
  }
  .reportContainer .competency-chip .label {
    font-weight: 600;
    margin-left: 12px;
    text-align: left;
    width: 180px;
    font-size: 16px;
  }
  .reportContainer .competency-chip .donut-contain {
    width: 96px;
    height: 96px;
    margin: 2px;
    border: none;
    border-radius: 90px;
    background: #ffffff;
  }
  .reportContainer .key-competency .key-competency-inner .competency-blurb {
    width: 49%;
    height: 200px;
    padding: 24px;
    color: #ffffff;
    font-weight: normal;
    background: #45aae1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12pt;
    line-height: 24pt;
  }
  /* detail pages */
  .reportContainer .competency-details {
    width: 100%;
  }
  .reportContainer .competency-details .competency-detail {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-top: 16px;
    border-top: 3px #cccccc solid;
  }
  .reportContainer .competency-details .competency-detail .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .reportContainer .competency-details .competency-detail .header .detail-icon {
    width: 58px;
    height: 58px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .header
    .detail-icon
    span {
    color: #ffffff;
    font-size: 38px;
  }
  .reportContainer .competency-details .competency-detail .header .name {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    width: 250px;
    margin-right: 16px;
    text-align: left;
  }
  .reportContainer .competency-details .competency-detail .header .bar {
    border-radius: 16px;
    border: 1px solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 150px;
    height: 22px;
    margin-left: 96px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .header
    .bar
    .bar-inner {
    border-radius: 16px;
    min-width: 10%;
    height: 20px;
  }
  .reportContainer .competency-details .competency-detail .header .level {
    font-weight: bold;
    text-align: right;
    width: 120px;
    justify-self: flex-end;
    margin-right: 0px;
    margin-left: 32px;
    font-weight: bold;
    font-size: 16px;
  }
  .reportContainer .competency-details .competency-detail .graphics-contain {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .subcompetencies {
    width: 45%;
  }
  .reportContainer .competency-details .competency-detail .graphics-contain h5 {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 20px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .subcompetencies
    .inline-labels {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .inline-labels
    .sc-label {
    padding: 4px 8px;
    margin: 4px;
    color: #ffffff;
    display: inline;
    white-space: nowrap;
    border-radius: 8px;
    word-wrap: normal;
    font-size: 13pt;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain {
    width: 300px;
    display: flex;
    margin-left: 4px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper {
    width: 50%;
    height: 100px;
    position: relative;
    left: 20px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper
    .bg {
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 120px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper
    .bg
    .line {
    height: 20%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    border-bottom: 1px #cccccc solid;
    font-size: 12px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper
    .bg
    .line:first-of-type {
    border-top: 1px #cccccc solid;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper
    .bg
    .line:last-of-type {
    height: 0px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper
    .bg
    .line
    span {
    margin-left: -34px;
    margin-top: -14px;
    color: #c0c0c0;
    text-align: right;
    width: 30px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper
    .chart {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 50;
    position: absolute;
    top: 0px;
    left: 0px;
    margin-right: 8px;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .chart-wrapper
    .chart
    .bar {
    width: 20px;
    margin-bottom: 0px;
    min-height: 10%;
    max-height: 120px;
    margin-left: 12px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #fff;
    border-bottom: 1px #cccccc solid;
  }
  /* mini chart legend */
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .legend {
    width: 240px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .legend
    .item {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 8px;
    align-items: center;
    justify-content: flex-start;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .legend
    .item
    .label {
    font-size: 10pt;
  }
  .reportContainer
    .competency-details
    .competency-detail
    .graphics-contain
    .mini-chart-contain
    .legend
    .item
    .bullet {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    max-width: 10px;
    max-height: 10px;
    margin-right: 8px;
    margin-left: 32px;
    background: #000000;
    border-radius: 16px;
  }
  .reportContainer .competency-details .competency-detail .lists {
    margin-top: 12px;
    padding-bottom: 8px;
  }
  .reportContainer .competency-details .competency-detail .lists p {
    font-size: 15px;
    line-height: 30px;
  }
  .reportContainer .competency-details .competency-detail .lists h5 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
  }
  .reportContainer .competency-details .competency-detail .lists ul {
    margin-top: -16px;
  }
  .reportContainer .competency-details .competency-detail .lists li {
    list-style-type: disc;
    font-size: 11pt;
    line-height: 18pt;
    margin-left: 24px;
    margin-bottom: 8px;
  }
  .reportContainer .competency-details .competency-detail .lists ul li span {
    margin-left: 16px;
    display: block;
  }
  .reportContainer .dimension-chart-key {
    width: 100%;
    border-bottom: 2px solid #cccccc;
    border-top: 2px solid #cccccc;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  .reportContainer .dimension-chart-key .item {
    width: 24.4%;
    color: #7a8d98;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    font-size: 11px;
  }
  .reportContainer .dimension-chart-key .item .bullet {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: 2 2 2px #ccc;
    margin-right: 4px;
  }
  .reportContainer .dimension-chart-key .item .label {
    margin-right: 8px;
    font-size: 10px;
  }
  /* notes block */
  .reportContainer .notes-block {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 300px;
  }
  .reportContainer .notes-block .line {
    border-bottom: 1px #cccccc solid;
    width: 100%;
    height: 50px;
  }
  /* utils  */
  .norm-marker {
    min-width: 5%;
    max-width: 95%;
  }
  /* colors */
  .reportContainer .bg-yellow {
    background: #eaaa00 !important;
  }
  .reportContainer .bg-yellow-80 {
    background: #eebb33 !important;
  }
  .reportContainer .bg-yellow-60 {
    background: #f2cc66 !important;
  }
  .reportContainer .text-yellow {
    color: #eaaa00 !important;
  }
  .reportContainer .bg-cyan {
    background: #00ccd9 !important;
  }
  .reportContainer .bg-cyan-80 {
    background: #33d6e1 !important;
  }
  .reportContainer .bg-cyan-60 {
    background: #66e0e8 !important;
  }
  .reportContainer .text-cyan {
    color: #00ccd9 !important;
  }
  .reportContainer .bg-red {
    background: #da0a00 !important;
  }
  .reportContainer .bg-red-80 {
    background: #e13b33 !important;
  }
  .reportContainer .bg-red-60 {
    background: #e96c66 !important;
  }
  .reportContainer .text-red {
    color: #da0a00 !important;
  }
  .reportContainer .bg-green {
    background: #009586 !important;
  }
  .reportContainer .bg-green-80 {
    background: #33aa9e !important;
  }
  .reportContainer .bg-green-60 {
    background: #66bfb6 !important;
  }
  .reportContainer .text-green {
    color: #009586;
  }
  .reportContainer .bg-purple {
    background: #c13bcd !important;
  }
  .reportContainer .bg-purple-80 {
    background: #cd62d7 !important;
  }
  .reportContainer .bg-purple-60 {
    background: #da89e1 !important;
  }
  .reportContainer .text-purple {
    color: #c13bcd !important;
  }
  .reportContainer .bg-navy {
    background: #3e4eb8 !important;
  }
  .reportContainer .bg-navy-80 {
    background: #6571c6 !important;
  }
  .reportContainer .bg-navy-60 {
    background: #8b95d4 !important;
  }
  .reportContainer .text-navy {
    color: #3e4eb8 !important;
  }
  .reportContainer .bg-pink {
    background: #eb1460 !important;
  }
  .reportContainer .bg-pink-80 {
    background: #ef4380 !important;
  }
  .reportContainer .bg-pink-60 {
    background: #f372a0 !important;
  }
  .reportContainer .text-pink {
    color: #eb1460 !important;
  }
  .debug {
    border: 1px red solid;
  }
  .debug .debug {
    border: 1px red dotted;
  }
  .reportContainer #downloadReport {
    position: relative;
  }
  .reportContainer #downloadReport .header {
    margin-top: 0px !important;
    position: relative;
    top: 0px !important;
    margin-bottom: 20px;
  }
  .reportContainer #downloadReport .footer {
    margin-bottom: 0px !important;
    position: absolute;
    bottom: 0px !important;
  }
  .reportContainer #downloadReport ul li span {
    margin-left: 16px;
    display: block;
  }
  .reportContainer #downloadReport .box-shadow-no-export {
    box-shadow: 0 !important;
  }
  .reportContainer #downloadReport .panel .header,
  .reportContainer #downloadReport .competency-panel .header {
    background: none !important;
  }
  /* Coaching report CSS */
  .competency-group-container .title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #111111;
    padding: 5px;
  }
  .competency-group-container .sub-container {
    display: flex;
    justify-content: space-evenly;
  }
  .competency-group-container .right {
    box-sizing: border-box;
    width: 164px;
    background: #45aae1;
    border: 1px solid #45aae1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 35%;
    padding: 10px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  .competency-group-container .left {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 55%;
  }
  .competency-group-container .competency {
    display: flex;
    justify-content: space-evenly;
  }
  .competency-group-container .competency .left-block {
    width: 65%;
  }
  .competency-group-container .competency .right-block {
    width: 25%;
  }
  .mnemonic .indent {
    margin-left: 30px;
  }
  .mnemonic h5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mnemonic .letter {
    width: 20px !important;
    display: inline-block;
  }
  ol {
    margin-left: 40px;
    margin-top: 10px;
  }

  #downloadReport ol li span,
  #downloadReport ul li span {
    margin-left: 20px;
  }

  .competency-panel {
    width: 100%;
    border-radius: 8px;
    padding: 0px !important;
    margin-bottom: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 1;
    min-height: 0px !important;
    border: 1px #b8cdd8 solid;
  }
  .reportContainer .competency-panel .heading {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px 8px 0px 0px;
  }
  .reportContainer .competency-panel .heading h2 {
    margin-left: 30px;
    color: #ffffff;
    font-size: 16pt;
  }
  .reportContainer .competency-panel .inner {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: 8px 0px 16px 8px;
    background: none !important;
  }
  .reportContainer .competency-panel .inner p {
    font-size: 12pt;
    line-height: 24pt;
    margin-left: 30px;
    width: 80%;
  }
  .reportContainer .competency-panel .inner ul {
    width: 92%;
  }
  .reportContainer .competency-panel .inner ul li {
    margin-left: 50px;
    list-style-type: disc;
    list-style-position: outside;
  }
  .reportContainer .competency-panel .inner .icon-contain {
    width: 80px;
    height: 80px;
    align-self: flex-end;
    justify-self: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 1;
    margin-right: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .reportContainer .competency-panel .inner .icon-contain span {
    font-size: 55px;
    font-weight: normal;
  }
  .reportContainer .footnotes {
    margin-top: 140px;
    padding-top: 10px;
    border-top: #cccccc 2px solid;
  }
  .reportContainer .bg-strength {
    background-color: #008b57 !important;
  }
  .reportContainer .fg-strength {
    color: #008b57;
  }
  .reportContainer .bg-priority {
    background-color: #5d2a5f !important;
  }
  .reportContainer .fg-priority {
    color: #5d2a5f;
  }
}
