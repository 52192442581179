@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,300&display=swap');
*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

  position: static;
  z-index: auto;
}

body {
  font-family: 'Noto Sans', sans-serif;
}

:root {
  --navy: #202e43;
  --darkblue: #00629b;
  --medblue: #1492d8;
  --accblue: #45aae1;
  --lightblue: #a2d4f0;
  --light: #f8fcff;
  --black: #25282b;
  --green: #08595f;
  --gold: #eaaa00;
  --darkgrey: #d7dbde;
  --darkblack: #111111;

  --ui-red-bg: #ffdddd;
  --ui-red: #f44336;

  --ui-green-bg: #ddffdd;
  --ui-green: #4caf50;

  --desktop: 1024px;
  --mobile: 1023px;
}

body {
  background: var(--light);
  color: var(--black);
  min-height: 100vh;
}

.debug {
  border: red 1px solid;
}

/* content display */

.screen {
  background-color: #fff;
  width: 100%;
  background-color: white;
}

/* skipnav link for screen readers only */
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.user-menu {
  /* padding: 20px; */
  min-height: 50px;
  min-width: 213px;
  position: absolute;
  right: 20px;
  top: 0px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.user-menu .header {
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-menu .header img {
  width: 40px;
  height: 40px;
  border-radius: 45px;
  border: 3px solid var(--darkblue);
}

.user-menu .header .icon {
  color: var(--darkblue);
  font-weight: 400;
}

.user-menu .list {
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 500;
}

.user-menu .list .item {
  width: 100%;
  font-weight: 300;
  background: #fff;
  font-size: 16px;
  line-height: 40px;

  height: 48px;
  padding-left: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0px;
}

.user-menu .list .item:hover {
  background: linear-gradient(0deg, #e8e8e8, #e8e8e8), #ffffff;
}

/* login splash */

@media screen and (min-width: 1024px) {
  .fw-hide {
    display: none !important;
  }

  .splash {
    width: 500px !important;
    /* margin: -100px auto; */
    display: flex;
    align-self: center;
    justify-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
  }

  .landing {
    width: 100vw;
  }

  .landing .top-bg {
    height: 50vh;
    width: 100vw;
    position: absolute;
    left: 0px;
    top: 0px;
    background: var(--navy);
  }

  .top-bg svg {
    margin: 0px;
    width: 207px;
    height: 60px;
    position: absolute;
    top: 20px;
    left: 10px;
  }

  .splash-contain {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .splash h1 {
    size: 48px;
    text-align: center;
    color: var(--black);
  }

  .splash p {
    color: var(--black);
    text-align: center;
    margin: 15px;
    padding: 10px;
  }

  /* continuing to put full-width css stuff here until i can reorganize everything :( */

  .table-menu {
    width: 100%;
    display: flex;
    align-items: space-between;
    padding: 5px;
  }

  .table-menu select {
    margin: 5px;
    border-radius: 4px;
    min-width: 150px;
  }

  .inner-detail {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .inner-detail .inner-detail-col {
    border: 1px red solid;
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .inner-detail .tags {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .inner-detail .tags li {
    background: var(--lightblue);
    color: var(--navy);
    border: var(--navy) 1px solid;
    border-radius: 3px;
    margin: 3px;
    padding: 2px;
    font-size: 9px;
  }

  .inner-detail select {
    padding: 0px;
    font-size: 9px;
    margin: 5px;
  }

  .inner-detail-col div {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inner-detail .ctrl {
    font-size: 9px;
    border-radius: 3px;
    background: var(--darkblue);
    border: 0px;
    color: #fff;
    padding: 3px 5px;
    margin: 2px 5px;
  }
}

@media screen and (max-width: 1023px) {
  .m-landing {
    width: 100vw;
    height: 100vh;
    background: var(--navy);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .m-landing svg {
    margin-top: 10px;
    height: 70px;
    width: 207px;
    padding: 0px;
  }

  .m-landing .splash {
    width: 95%;
    height: 55%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .m-landing .splash h1 {
    font-weight: lighter;
  }

  .m-landing .splash p {
    text-align: center;
    padding: 10px;
  }

  .m-landing .splash button {
    width: 98%;
    border: 0px;
    color: #fff;
    background: var(--darkblue);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 20px;
    justify-self: flex-end;
    font-size: 18px;
    font-weight: 700;
  }
}

/* hide sidebar on mobile. show nav and header. */

@media screen and (max-width: 1023px) {
  .sidebar,
  .user-menu {
    display: none !important;
  }

  .mobile-nav {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #fff;
    box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
    display: flex;
    justify-content: space-around;
  }

  .mobile-nav button {
    height: 100px;
    min-width: 15%;
    border: 0px;
    background: #fff;
    color: var(--darkblue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-shadow: 4px 0px 1px rgba(16, 30, 115, 0.08); */
  }

  .mobile-header {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
  }

  .mobile-header img {
    width: 220px;
    padding: 20px;
  }

  h1,
  h2,
  h3 {
    text-align: center;
  }

  .page {
    margin-bottom: 120px;
  }

  .fw-footer {
    display: none;
  }
}

/* show sidebar on full-width. hide mobile nav and header. */

@media screen and (min-width: 1024px) {
  html,
  body {
    width: 100%;
    height: 100vh;
  }

  .mobile-nav,
  .mobile-header {
    display: none !important;
  }

  .sidebar {
    width: 256px;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    z-index: 5;
    background: var(--navy);
    box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
  }

  .sidebar .logo-contain {
    width: 100%;
    height: 70px;
    margin-top: 10px;
    display: flex;
  }

  .sidebar svg {
    width: 207px;
  }

  .sidebar ul {
    list-style-type: none;
  }

  .sidebar ul li a {
    display: block;
    width: 100%;
    padding-left: 32px;
    padding-top: 14px;
    padding-bottom: 14px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
  }

  .sidebar ul li a:hover,
  .sidebar ul li a.active {
    color: #ffffff;
  }

  .sidebar ul li a:hover::before,
  .sidebar ul li a.active::before {
    content: '';
    background: var(--accblue);
    border-radius: 45px;
    position: absolute;
    left: 0px;
    top: 5px;
    margin-right: 20px;
  }

  .page {
    position: relative !important;
    z-index: 1;
    padding: 20px;
    margin-bottom: 100px;
    min-height: 100vh;
  }

  .fw-footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0px;
  }
}

/*  reusable utilities */

.pull-right {
  float: right;
}

.offset-top {
  position: relative;
  bottom: -20px;
  right: 10px;
  z-index: 20;
}

.afw {
  width: 90% !important;
}

.fh {
  height: 100% !important;
}

.ctr-dist {
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.ctr-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ctr-me {
  text-align: center !important;
}

.svg-icon {
  margin: 5px;
}

.button-none {
  border: 0px;
  background: none;
}

.button-clear {
  border: 0px;
  background: none;
  padding: 5px;
  margin: 5px;
}

.invisible {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.center-me {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mx-override {
  margin: 0px !important;
}

.error-bg {
  background: var(--ui-red-bg);
}

.success-bg {
  background: var(--ui-green-bg);
}

.row {
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
}

.row-item {
  min-width: 25%;
}

.rows {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.svg-placeholder {
  width: 1em;
  height: 1em;
  margin: 5px;
}

/* reusable display components */

@media screen and (max-width: 1023px) {
  .alert-box {
    width: 95%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .alert-box {
    width: 540px;
  }
}

@media screen and (max-width: 1023px) {
  /* mobile */
}

.alert-box {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.alert-box h4 {
  font-size: 30px;
  padding: 20px;
}

.alert-box p {
  font-size: 12px;
}

/* // lists for in-page menus */
.page-menu {
  width: 40%;
}

.page-menu li {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
}

.page-menu li a {
  color: var(--darkblue);
}

.page-menu li a:hover {
  color: var(--navy);
}

a {
  text-decoration: none;
}

/* buttons and form elements */
.button {
  background: var(--darkblue);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #fff;
  padding: 10px 20px;
  border: 0px;
  margin: 10px;
}

/* rounder corners, circle at min width */
.button-alt {
  border: 0px;
  padding: 11px 18px;
  border-radius: 100px;
  color: #fff;
  margin-left: 20px;
  font-weight: 700;
  line-height: 20px;
  font-size: 18px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.button-shadow {
  border: 3px solid #cccccc;
}

/* buttons from pattern library */

.button-text {
  color: var(--darkblue);
  font-weight: 700;
  font-size: 18px;
}

.button-text:focus {
  border-bottom: 3px var(--medblue) solid;
  padding-bottom: 3px;
}

.button-text:hover {
  opacity: 0.8;
}

.button-outline {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 13px;
  padding-right: 13px;
  border: 3px var(--darkblue) solid;
  border-radius: 4px;
  background: #fff;
}

.button-outline:focus {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-outline:hover {
  opacity: 0.8;
}

/* button overrides for inputs */
.form-button {
  margin-right: 0px !important;
}

/* todo: contained button high emphasis */

/* assessment utilities */

.assessment-wrapper h1 {
  text-align: left;
}

.notice {
  margin: 20px;
  width: 50%;
}

.notice .notice-text {
  /* margin: 0px!important; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px !important;
}

.notice a {
  color: var(--darkblue);
}

.qst {
  /* parent class for question */
  box-sizing: border-box;
  margin-bottom: 80px;
  margin-top: 40px;
  margin-left: 20px;
  /* border-bottom: #cccccc 1px solid; */
}

.qst hr {
  color: #cccccc;
  width: 500px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.qst fieldset {
  border: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.qst-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;
  margin: 10px;
}

.qst-inner p {
  width: 50%;
  padding: 0px !important;
  overflow: wrap;
}

.qst-title {
  /* title */
  font-weight: normal;
  font-size: 16px !important;
  line-height: 40px;
  margin-left: -18px;
  margin-top: 40px;
  padding-left: 0px !important;
  text-align: left;
  color: #25282b !important;
}

.qst h2 {
  font-size: 16px !important;
  line-height: 30px;
  margin-left: -18px;
  margin-bottom: 10px;
  padding-left: 0px !important;
  text-align: left;
}

.qst input[type='radio'] {
  -webkit-appearance: none;
  display: inline-block;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  padding: 6px;
  background: #fff;
  color: var(--accblue);
  border: 2px var(--accblue) solid;
  border-radius: 45px;
  margin-right: 20px;
}

.qst-options {
  margin-top: 10px;
}

.qst input[type='radio']:checked {
  background: var(--accblue) !important;
  box-shadow: 0px 0px 0px 3px var(--accblue);
  border: 2px white solid !important;
}

/* .qst input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  color: #fff;
  border-radius: 3px;
  border: 3px var(--gold) solid;
  width: 30px;
  height: 30px;
  margin: 15px;
}

.qst input[type="checkbox"]:checked {
  color: var(--gold);
  -webkit-appearance: none;
  appearance: none;
} */

/* checkbox  */
.qst input[type='checkbox'] {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px);
}

.qst input[type='checkbox'] + label {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
}

.slider-labeled {
  margin-top: 100px;
}
.qst label span {
  color: var(--darkblack);
}

/* .qst label .label-left {
  float: left;
}

.qst label .label-right {
  float: right;
} */

.qst input[type='checkbox'] + label::before {
  content: '';
  position: relative;
  display: inline-block;
  margin-right: 11px;
  min-width: 30px;
  min-height: 30px;
  background: white;
  border: 4px var(--accblue) solid;
  border-radius: 3px;
}

.qst input[type='checkbox']:checked + label::before {
  background: var(--accblue);
}

.qst input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  top: 24px;
  left: 14px;
  border-left: 4px solid var(--darkblack);
  border-bottom: 4px solid var(--darkblack);
  height: 10px;
  width: 22px;
  transform: rotate(-45deg);
}

.qst .checkboxContainer .qst-inner label {
  height: 68px;
}
.qst input[type='checkbox']:focus + label::before {
  outline: var(--gold) solid 2px;
  box-shadow: 0px 0px 8px var(--darkblue);
}

.qst .button-selected {
  background: var(--gold);
  color: var(--darkblack);
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 12px;
  padding-top: 12px;
}

.qst .button-inactive {
  background: #fff;
  color: var(--darkblack);
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 12px;
  padding-top: 12px;
  border: 3px solid #ccc;
  border-radius: 4px;
}

/* color modifiers and overrides */

.accent {
  background: var(--accent);
}

.btext {
  color: var(--darkblack) !important;
}

.wtext {
  color: #fff;
}

.wbg {
  background: #ffffff !important;
}

.bbg {
  background: var(--darkblack) !important;
}

.red {
  background-color: darkred;
}

.green {
  background-color: var(--green);
}

.navy {
  background-color: var(--green);
}

.gold {
  background-color: var(--gold);
}

.grey {
  background-color: grey;
}

/* form positioning and styling */

.form {
  margin: 25px;
  width: 540px;
}

.form .element {
  margin-bottom: 20px;
  width: 100%;
}

.form .element .label {
  text-align: left;
  margin-bottom: 10px;
}

.form .element:last-of-type {
  margin-bottom: 100px;
}

.form .element button {
  float: right;
}

.form .element input[type='text'],
.form .element input[type='number'],
.form .element select,
.form .element select {
  padding-left: 15px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: 1px rgba(0, 0, 0, 0.25) solid;
  padding-top: 14px;
  padding-bottom: 14px;
  color: var(--black);
  width: 100%;
  border-radius: 3px;
  margin-bottom: 15px !important;
}

.form .element textarea {
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: var(--black);
  border: 1px rgba(0, 0, 0, 0.25) solid;
  width: 100%;
  height: 200px;
}

.form .element button {
  margin-right: 0px !important;
}

select.multi-select {
  border: 0px;
  box-shadow: 0px !important;
  padding: 0px !important;
}

select.multi-select option {
  padding: 2px;
  box-shadow: none !important;
}

.multi-select option:hover {
  background: var(--lightblue);
  box-shadow: 0px !important;
}

select,
option {
  padding: 13px;
  color: var(--black);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

textarea {
  padding: 10px;
  color: var(--black);
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  height: 60px;
  width: 300px;
  border: 1px rgba(0, 0, 0, 0.25) solid;
  border-radius: 3px;
}

/* slider: assessment control */

.qst .slider-contain {
  margin-bottom: 20px;
  margin-top: 0px;
}

.qst .slider-labeled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.qst .slider-labeled span {
  width: 200px;
  font-size: 12px;
}

.qst .slider-labeled span.label-right {
  text-align: right;
}

.qst input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;

  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 10px var(--accblue) solid;
  cursor: pointer;
  outline: none;
  z-index: 10;
  background: #fff;
}

.qst input[type='range'].unanswered::-webkit-slider-thumb {
  -webkit-appearance: none;

  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 10px var(--darkgrey) solid;
  cursor: pointer;
  outline: none;
  z-index: 10;
  background: #fff;
}

.qst input[type='range'] {
  margin-top: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #eeeeee;
  border-radius: 45px;
  outline: none;
  transition: opacity 0.2s;
}

.slider {
  height: 30px;
  background: var(--green);
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: nonne;
  appearance: none;
  width: 30px;
  height: 30px;
  background: var(--green);
}

/* typography */

h1 {
  font-size: 48px;
  line-height: 46px;
  padding: 24px;
  letter-spacing: 0.2px;
  font-weight: 300;
  line-height: 46px;
  color: var(--black);
}

h2 {
  font-size: 20px;
  line-height: 26px;
  padding: 20px;
}

.inline-navigational {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--darkblack);
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: solid 2px green;
  margin: 10px;
}

ul {
  list-style-type: none;
}

.smaller {
  font-size: 12px;
}

.smallest {
  font-size: 9px;
}

/* margins and padding */

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

/* component styling */
.card {
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  width: 100%;
  margin: 10px;
  overflow: hidden;
  border: #ccc 1px solid;
}

.card-barebones {
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  overflow: hidden;
  border: #ccc 1px solid;
  padding: 10px;
  height: auto;
}

.card-barebones .back,
.card .back {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.card-barebones .back button,
.card .back button {
  border-right: 0px;
  border-top: 0px;
  border-left: 0px;
  background: none;
  border-bottom: 1px var(--gold) solid;
}

/* mobile assessment modifications */
@media screen and (max-width: 960px) {
  .assessment-wrapper {
    width: 98vw !important;
    max-width: 98vw !important;
    /* padding: 20px; */
  }

  .qst {
    width: 100% !important;
    margin-left: 0px !important;
    padding: 0px;
  }

  .qst-title,
  .qst h2,
  .qst h3 {
    margin: 0px !important;
    width: 98% !important;
    padding: 10px !important;
    box-sizing: border-box;
  }

  .qst-inner {
    width: 92% !important;
    box-sizing: border-box;
  }

  .qst .slider-contain {
    width: 90% !important;
    box-sizing: border-box;
    padding: 0px !important;
    margin: 0px !important;
  }

  .assessment-wrapper h1 {
    text-align: center;
  }
}

/* // width utilities, go by common screen sizes! */

@media screen and (min-width: 1024px) {
  .w25 {
    width: 250px !important;
  }

  .w50 {
    width: 500px !important;
  }

  .w75 {
    width: 750px !important;
  }

  .w100 {
    width: 1000px !important;
  }
}

@media screen and (max-width: 1023px) {
}

.w25 {
  width: 24%;
}

.w50 {
  width: 49%;
}

.w75 {
  width: 74%;
}

.w100 {
  width: 100%;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card:last-of-type {
  margin-bottom: 100px;
}

.noMarginBottom.card:last-of-type {
  margin-bottom: 0px;
}

.card p {
  padding: 20px;
}

.card .subtitle {
  margin-left: 40px;
}

.card table {
  table-layout: fixed;
  width: 100%;
  margin: 10px auto;
  border-collapse: collapse;
}

/* @media screen and (min-width: 1024px) {
  .card table.half {
    width: 50%;
    margin-left: 0px!important;
  }
} */

.card table thead {
  background: var(--lightblue);
  border-bottom: 1px solid #e8e8e8;
}

.card table thead th {
  border: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: left;
  padding: 18px;
  margin: 0px;
}

.card table tbody tr {
  border-bottom: 1px solid #e8e8e8;
  padding: 18px;
}

.card table tbody tr:last-of-type {
  border-bottom: 0px !important;
}

.card table tbody tr td {
  padding: 18px;
}

.card table tbody tr td.control {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.card table tbody tr td.num {
  width: 50px;
}

.card table tbody tr td .text {
  max-width: 20%;
}

.card .sort {
  border: 0px;
  padding: 5px;
  color: var(--darkblue);
  background: none;
  font-weight: boldest;
}

@media screen and (min-width: 1024px) {
  .card .form {
    width: 840px;
  }

  .card .form .element:last-of-type {
    margin-bottom: 50px;
    text-align: right;
  }
}

/* mobile modifications */

@media screen and (max-width: 1023px) {
  .mobile-hide {
    display: none;
  }

  .card {
    width: 95% !important;
    margin: 10px auto !important;
  }

  .card table th,
  .card table tbody td {
    font-size: 12px;
  }

  .card table {
    background: #fff;
  }

  .card table tbody,
  .card table thead {
    padding: 0px;
  }

  .card table thead {
    display: none;
  }

  .card table thead th,
  .card table tbody td {
    padding: 10px !important;
    /* border: red 2px solid; */
  }

  .qst-inner {
    width: 100%;
  }
  /* form element modifications*/

  .form {
    max-width: 90%;
  }

  .form .element {
    width: 100%;
  }

  .form .element:last-of-type {
    margin-bottom: 100px;
  }

  .form .element button {
    float: right;
    margin: 0px !important;
  }

  input[type='text'],
  input[type='textarea'],
  textarea {
    width: 100% !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .form .element .input textarea {
    max-height: 50px !important;
  }
}

.page-nav {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-bottom: -40px;
  padding-right: 40px;
}

/* PAGINATION CSS */
@media screen and (max-width: 960px) {
  /* mobile pagination needs to all be visible within the viewport */
  .paginator-contain {
    margin: 10px;
    padding: 10px;
    width: 96vw !important;
    max-width: 96vw !important;
    min-width: 96vw !important;
  }
}

.paginator-contain {
  margin: 10px;
  padding: 10px;
  width: fit-content;
  overflow: auto;
  min-width: 600px;
}

.paginator {
  display: inline-flex;
  flex-direction: row;
  list-style-type: none;
}

.paginator li.paginator-item {
  color: var(--darkblack);
  display: block;
  text-decoration: none;
  background: #fff;
  margin: 5px;
  padding: 5px;

  font-size: 13px;
}

.paginator li.paginator-item.current {
  border-bottom: var(--gold) solid 2px;
  color: var(--darkblack);
  text-decoration: none;
}

.paginator li a.paginator-link {
  color: inherit;

  text-decoration: none;
}

/* // survey section */

.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.survey-options {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  list-style-type: none;
}

.survey-button-unselected {
  min-width: 100px;
  padding: 10px;
  background: #fff;
  color: var(--darkblue);
  text-align: center;
  border: 0px;
  /* border: 2px solid var(--darkblue); */
  border-radius: 4px;
  /* box-shadow: 2px 2px 2px #ccc; */
  margin-top: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.survey-button-selected {
  min-width: 100px;
  padding: 10px;
  background: #fff;
  color: var(--darkblue);
  border: 0px;
  text-align: center;
  /* border: 2px solid var(--darkblue);
   */
  border-radius: 4px;
  /* box-shadow: 2px 2px 2px #ccc; */
  margin-top: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.survey-button-unselected .box,
.survey-button-selected .box {
  width: 30px !important;
  height: 30px !important;
  min-width: 30px;
  min-height: 30px;
  border: 2px var(--darkblue) solid;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--darkblue);
  margin-right: 10px;
}

.survey-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.survey-nav button {
  background: #fff;
  color: var(--navy);
  border: 0px;
  padding: 5px;
  border-bottom: 1px var(--gold) solid;
  margin: 5px;
}

.header-bar .counter {
  font-size: 11pt;
  font-weight: bold;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
}

.trait-list {
  margin-top: 10px;
  list-style-type: none;
  font-size: 12px;
}

.trait-list li button {
  padding: 5px;
  border: 0px;
  background: none;
  color: var(--darkblue);
}

/* .trait-list li.selected button{
  font-weight: bold;
  margin-left: 0px;
  color: var(--darkblue);
  
}

.trait-list li.displayed button {
  font-size: 105%;
  color: var(--darkblue);
} */

/* trait report page special styling  */
.trait-response-report {
  margin-left: 10px;
  list-style-type: none;
  max-width: 400px;
}

.trait-response-report li {
  padding: 10px;
  margin: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.trait-response-report li.more {
  background: var(--ui-green-bg);
  border: var(--ui-green) 1px solid;
}

.trait-response-report li.less {
  background: var(--ui-red-bg);
  border: var(--ui-red) 1px solid;
}

.invite-code-contain {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.invite-code-contain input {
  padding: 8px;
  margin: 10px;
}

.card h2 {
  margin-left: 20px;
  /* padding-bottom: 10px; */
}

.card a {
  color: var(--darkblue);
  text-decoration: underline;
}

.alert {
  background: #fffbe5;
  padding: 10px;
  font-size: 14pt;
  margin-top: 10px;
}

.alert span {
  margin-left: 10px;
}
textarea::placeholder {
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
}

#updateTicketModal input,
#updateTicketModal .MuiSelect-select {
  padding: 12px;
  padding-left: 25px;
}

#updateTicketModal textarea {
  padding-left: 10px;
}

.flexReverse .MuiTabs-flexContainer {
  flex-direction: row-reverse;
}
