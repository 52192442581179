#dvmt2 {
  p {
    margin-bottom: 10px;
  }

  .dimension-definitions-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;

    .dimension {
      display: flex;
      align-items: start; /* Aligns items at the top */
      margin-top: 15px;
      height: 57px;
      margin-right: 12px;
      box-sizing: content-box;

      // Remove margin-right when grid-column is 2
      &[style*='grid-column: 2'] {
        margin-right: 6px;
      }
    }

    .dimension-icon {
      width: 46px;
      height: 46px;
      margin-right: 9px; /* Space between icon and text */
    }

    .dimension-title {
      color: #3d3d3d;
      font-family: Catamaran;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.12px;
      margin-bottom: 0;
    }

    .dimension-description {
      color: #4d4c4c;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.12px;
    }
  }
}
