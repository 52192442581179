@font-face {
  font-family: 'Flaticon';
  src:
    url('./Flaticon.svg#Flaticon') format('svg'),
    url('./Flaticon.woff2') format('woff2'),
    url('./Flaticon.woff') format('woff'),
    url('./Flaticon.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('./Flaticon.svg#Flaticon') format('svg');
  }
}

.fimanager:before {
  display: inline-block;
  font-family: 'Flaticon' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* font-smoothing: antialiased; */
  display: block !important;
}

.flaticon-001-chat-bubble:before {
  content: '\f100';
  font-family: 'Flaticon' !important;
}
.flaticon-002-presentation:before {
  content: '\f101';
  font-family: 'Flaticon' !important;
}
.flaticon-003-speech:before {
  content: '\f102';
  font-family: 'Flaticon' !important;
}
.flaticon-004-respect:before {
  content: '\f103';
  font-family: 'Flaticon' !important;
}
.flaticon-005-manner:before {
  content: '\f104';
  font-family: 'Flaticon' !important;
}
.flaticon-006-adaptive:before {
  content: '\f105';
  font-family: 'Flaticon' !important;
}
.flaticon-007-change:before {
  content: '\f106';
  font-family: 'Flaticon' !important;
}
.flaticon-008-honest:before {
  content: '\f107';
  font-family: 'Flaticon' !important;
}
.flaticon-009-morality:before {
  content: '\f108';
  font-family: 'Flaticon' !important;
}
.flaticon-010-humor:before {
  content: '\f109';
  font-family: 'Flaticon' !important;
}
.flaticon-011-friendly:before {
  content: '\f10a';
  font-family: 'Flaticon' !important;
}
.flaticon-012-hourglass:before {
  content: '\f10b';
  font-family: 'Flaticon' !important;
}
.flaticon-013-settings:before {
  content: '\f10c';
  font-family: 'Flaticon' !important;
}
.flaticon-014-positive-thinking:before {
  content: '\f10d';
  font-family: 'Flaticon' !important;
}
.flaticon-015-encourage:before {
  content: '\f10e';
  font-family: 'Flaticon' !important;
}
.flaticon-016-selfconfident:before {
  content: '\f10f';
  font-family: 'Flaticon' !important;
}
.flaticon-017-social:before {
  content: '\f110';
  font-family: 'Flaticon' !important;
}
.flaticon-018-supporter:before {
  content: '\f111';
  font-family: 'Flaticon' !important;
}
.flaticon-019-tie:before {
  content: '\f112';
  font-family: 'Flaticon' !important;
}
.flaticon-020-reliable:before {
  content: '\f113';
  font-family: 'Flaticon' !important;
}
.flaticon-021-inspiration:before {
  content: '\f114';
  font-family: 'Flaticon' !important;
}
.flaticon-022-teamwork:before {
  content: '\f115';
  font-family: 'Flaticon' !important;
}
.flaticon-023-alarm-clock:before {
  content: '\f116';
  font-family: 'Flaticon' !important;
}
.flaticon-024-loyalty:before {
  content: '\f117';
  font-family: 'Flaticon' !important;
}
.flaticon-025-listener:before {
  content: '\f118';
  font-family: 'Flaticon' !important;
}
.flaticon-026-idea:before {
  content: '\f119';
  font-family: 'Flaticon' !important;
}
.flaticon-027-coorperation:before {
  content: '\f11a';
  font-family: 'Flaticon' !important;
}
.flaticon-028-tie:before {
  content: '\f11b';
  font-family: 'Flaticon' !important;
}
.flaticon-029-rise:before {
  content: '\f11c';
  font-family: 'Flaticon' !important;
}
