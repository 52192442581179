:root {
  --blue: #00629b;
}

.contain {
  width: 1122px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.row {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.col-100 {
  width: 1100px;
  margin-bottom: 20px;
}

.col-75 {
  width: 900px;
  margin-bottom: 20px;
}

.col-50 {
  width: 455px;
  margin-bottom: 20px;
}

.top-message {
  background: #ecf6fc;
  border-radius: 8px;
  min-height: 150px;
  padding: 30px;
  position: relative;
}

.top-message .top-img {
  position: absolute;
  right: 20px;
  bottom: 0px;
}

.top-message h3 {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 50px;
}

.top-message p {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.assessment-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.assessment-select {
  background: #ffffff;
  border: 1px solid #b8cdd8;
  box-sizing: border-box;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  min-width: 220px;
  width: auto;
  padding-right: 10px;
  height: 70px;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.assessment-select.outlined {
  border: 2px solid #45aae1;
}

.assessment-select.inactive {
  pointer-events: none;
  border: 1px solid #b8cdd8;
}

.assessment-select:hover {
  background: #fafdff;
  cursor: pointer;
}

.assessment-select .svg-bg {
  background: #c3dae8;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment-select span {
  font-size: 16px;
  margin: 10px;
}

h2 {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;

  letter-spacing: 0.2px;

  /* Neutral / Black */

  color: #25282b;
}

.panel-base {
  background: #ffffff;
  border: 2px solid #45aae1;
  box-sizing: border-box;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
}

.panel-base.inactive,
.panel-base.chart-base {
  border: 1px solid #b8cdd8;
}

.donuts {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inactive::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8fcff;
  border-radius: 8px;
  opacity: 0.7;
}

.donut-meter-contain {
  /* max-width: 300px!important; */
  position: relative;
}

.logo {
  /* margin-top: 30px; */
  /* background: #fff; */
}

.donut-meter-contain h4 {
  text-align: center;
  margin-top: 10px;
}

.donut-text {
  font-size: 6px;
}

.total-apps-dd {
  display: flex;
  width: 600px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.total-apps-dd h2 {
  margin-left: 20px !important;
}

.total-apps-dd select {
  min-width: 100px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.line-chart {
  padding: 20px;
}

.job-searches-contain {
  /* border: 1px red solid; */
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.chart-number {
  font-size: 8px !important;
}

.job-searches-contain h2 {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  font-size: 40px;
  line-height: 46px;
  /* or 115% */

  letter-spacing: 0.2px;

  /* Neutral / Black */

  color: #25282b;
}

.job-searches-contain .search-panel {
  width: 200px;
  height: 147px;
  background: #ffffff;
  /* card / floating */
  margin-right: 2rem;
  margin-bottom: 30px;
  padding: 10px;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #0d0d0d;
}

.job-searches-contain .search-panel:hover {
  background: #fafdff;
}

.search-panel svg {
  width: 100%;
}

.dash-header {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 46px;
}

.search-panel h4 {
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.table-controls {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.button-export {
  align-self: flex-end;
}
