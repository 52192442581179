#dvmt2 .dvmt2-indicators {
  p {
    margin-bottom: 11px;
    font-size: 10px;
  }

  h5 {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
    letter-spacing: 0.12px;
    padding-left: 0;
  }

  h6 {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 360% */
    letter-spacing: 0.1px;
  }

  .sub-competency-charts-container {
    width: 100%;
    display: flex;
    justify-content: initial;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .sub-competency-chart {
    width: 33%;
  }

  .sub-competency-chart-donut {
    height: 90px;
  }

  .sub-competency-name {
    color: #3d3d3d;
    text-align: center;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
    letter-spacing: 0.12px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  li {
    color: #4d4c4c;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    letter-spacing: 0.12px;
    margin-bottom: 3px;
    padding-left: 20px;
  }
}
