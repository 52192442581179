#dvmt2 #competency-overview-chart {
  //height: 538px;
  //width: 100%;
  //background-color: lightyellow;

  .legend {
    p {
      color: #111;
      text-align: right;
      font-family: 'Source Sans 3';
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 120% */
      margin-bottom: 0;
      margin-right: 4px;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    //background-color: lightyellow;
    //height: 10px;
  }
}
