.qst-number {
  margin-top: 20px;
  margin-left: 10px;
  font-weight: 600;
  color: #25282b !important;
}

.qst-title {
  margin-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  font-size: 14pt !important;
  text-align: left;
  margin-top: 10px;
  padding-left: 0px !important;
  color: #25282b !important;
}

.item-container .item-answer-container {
  margin: 10px;
  padding: 12px 10px;
  border-style: solid;
  border-color: #3399ff;
  border-radius: 3px;
  border-width: 1px;
  background-color: #eeee;
  display: flex;
  justify-content: space-between;
}

.item-container .item-answer {
  display: flex;
}

.item-container .item-answer .item-answer-number {
  padding: 4px 8px;
  margin-right: 15px;
  border-style: solid;
  border-color: #3399ff;
  border-radius: 2px;
  border-width: 1px;
  height: fit-content;
  align-self: center;
}

.item-container .item-answer-container .item-answer-icon {
  color: #3399ff;
  align-self: center;
}
