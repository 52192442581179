.assessment-frame {
  background: #fefefe;
}

.assessment-frame .header {
  padding: 5px;
  margin: 5px;
}

.assessment-frame .header h3 {
  background: var(--primary-color);
  color: #fff;
  padding: 5px;
}

.assessment-frame .header .meta {
  padding: 5px;
  background: #eee;
}

.question {
  background: #fdfdfd;
  border: 1px solid #dfdfdf;
}

.question h2 {
  background: var(--primary-color);
  color: #fff;
  line-height: 120%;
  padding: 5px;
}

.question-meta {
  border: 1px solid #fefefe;
}

.question-meta p {
  padding: 5px;
}

.question-body {
  background: #fff;
}

.sliding-scale {
  width: 80%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
}

.sliding-scale .scale-contain {
  margin: 10px auto;
  width: 100%;
}

.sliding-scale .scale-contain .scale {
  width: 100%;
  background: var(--primary-color);
  height: 10px;
  border-radius: 45px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  transition: opacity 0.2s;
}

.sliding-scale .scale-contain .scale::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 45px;
  box-shadow: 0px 0px 3px #000;
}

.sliding-scale .scale-labels {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checklist-contain ul {
  list-style-type: none;
}
