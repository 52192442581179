@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Open+Sans:wght@600&display=swap');
@import url('../src/css/fontello.css');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
}

p,
span,
div,
a,
button {
  font-family: 'Lato', sans-serif;
  font-size: 12pt;
  text-decoration: none;
}

input[type='textarea'] {
  width: 50%;
}

:root {
  --primary-color: #00629b;
  --primary-med: #b2d0e1;
  --primary-light: #e6eff5;

  --error-light: #f2d7d5;
  --error-med: #a93226;
  --error-dark: #641e16;

  --success-light: #d0ece7;
  --success-med: #138d75;
  --success-dark: #0b5345;

  --yellow-light: #fff59d;
  --yellow-dark: #fdd835;

  --orange-light: #ffcc80;
  --orange-dark: #ef6c00;
}

/* UTILITIES, STANDARD ELEMENTS & RESETS */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.mx-auto {
  margin: 0px auto;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-across {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

/* typography misc */
.emphasis {
  color: var(--primary-color);
  font-weight: 700;
  font-size: inherit;
}

.button-primary {
  margin: 10px;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 3px;
  padding: 5px 15px;
  box-shadow: 0px 0px 3px #dfdfdf;
  border: 0px;
}

.button-secondary {
  margin: 10px;
  background: var(--orange-dark);
  color: #ffffff;
  border-radius: 3px;
  padding: 5px 15px;
  box-shadow: 0px 0px 3px #dfdfdf;
  border: 0px;
}

@media screen and (max-width: 760px) {
  .App {
    width: 95%;
    margin-top: 5%;
  }
}

.App {
  width: 70%;
  margin-top: 100px;
}

.fillwidth {
  width: 100%;
}

.th-landing img {
  width: 200px;
  display: block;
  margin: 20px;
}

.ui-button {
  background: var(--primary-color);
  padding: 10px;
  color: #fff;
  border: 0px;
  border-radius: 6px;
  margin: 2px;
  display: block;
  text-decoration: none;
  text-align: center;
}

.navbar {
  background: var(--primary-color);
  height: 60px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
}

.navbar-logo {
  background: #ffffff;
  width: 50px;
  height: 50px;
  margin-left: 1px;
  margin-top: 1px;
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-logo img {
  width: 48px;
  height: 48px;
}

.navbar ul {
  color: #ffffff;
  list-style-type: none;
  width: 30%;
  position: absolute;
  right: 50px;
}

.navbar ul li {
  margin-left: 20px;
  display: inline;
  float: right;
}

.navbar ul li button,
.navbar ul li a {
  background: none;
  border: 0px;
  color: #ffffff;
}

.content-blurb {
  width: 100%;
  padding: 30px;
  background: #eeeeee;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #cccccc;
  min-height: 100px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.content-blurb p {
  line-height: 120%;
}

.content-blurb .blurb-footer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 80%;
}

/* FORM INPUT */

.form-element {
  width: 30%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
}

.search > label {
  margin: 10px;
}
.search input[type='text'] {
  padding: 10px;
  border: 1px #cecece solid;
  border-radius: 3px;
  margin-left: 10px;
}

.search input[type='submit'] {
  background: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 3px;
  padding: 5px 15px;
  align-self: flex-end;
  justify-self: flex-end;
}

.perms-list h3 button {
  background: var(--primary-color);
  border: 0px;
  border-radius: 2px;
  padding: 2px 10px;
  color: #ffffff;
  margin-left: 75px;
}

/* LISTS/CHECKLISTS COMPONENTS */

.check-list {
  width: 90%;
  margin-top: 50px;
  background: #eeeeee;
  padding: 5px;
  border-radius: 4px;
}

.check-list .even {
}

.check-list .odd {
}

.check-list .check-contain {
  width: 10%;
}

.check-list button {
  border: 0px;
  margin: 4px;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #c0c0c0;
}

.check-list .select {
  background: var(--primary-color);
  color: #ffffff;
}

.check-list .grant {
  background: var(--success-med);
  color: #ffffff;
}

.check-list .revoke {
  background: var(--error-dark);
  color: #ffffff;
}

.check-list td,
.check-list th {
  padding-right: 10px;
  text-align: left;
}

/* ERROR AND SUCCESS MESSAGES */
.message-alert {
  width: 50%;
  margin: 30px auto;
  border-radius: 5px;
  padding: 10px;
}

.message-alert h4 {
  font-size: 150%;
  display: inline;
  max-width: 70%;
}

.message-alert p {
  line-height: 120%;
}

.message-alert .header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.message-alert > button {
  border-radius: 3px;
  margin: 5px;
}

.error {
  background: var(--error-light);
  color: var(--error-dark);
}

.error > button {
  background: var(--error-med);
  color: #fff;
}

.success {
  background: var(--success-light);
  color: var(--success-dark);
}

.success > button {
  background: var(--success-med);
  color: #fff;
}

/* CREATE ASSESSMENT FORM */
.assessment-form {
}

.assessment-form h2 {
  color: var(--primary-color);
  text-align: center;
}

.assessment-form .form-item {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.form-item > button {
  background: var(--primary-color);
  color: #fff;
  padding: 5px 15px;
  border: 0px;
  border-radius: 3px;
}

.form-item input,
.form-item select {
  padding: 5px;
  margin: 10px;
  min-width: 65%;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-group .radio-item {
  display: flex;
  flex-direction: row;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.radio-group .radio-item span {
  margin: 10px;
}

.radio-group .radio-item input[type='textarea'] {
  margin: 10px;
}

.radio-group .radio-item button {
  border: 0px;
  border-radius: 3px;
  padding: 5px 15px;
  background: var(--orange-light);
}

.radio-group .radio-item button.correct {
  background: var(--success-med);
}

/* question edit */

.question-edit {
  background: #f0f0f0;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.question-edit .form-item {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

/* VIEW ASSESSMENT LIST */

.assessment-list {
  width: 80%;
  margin: 50px auto;
}

.assessment-list th,
.assessment-list td {
  text-align: left;
  /* margin-bottom: 5px;
  margin-top: 5px; */
  padding: 10px;
}

.assessment-list button {
  min-width: 100px;
  font-size: 9pt;
  padding: 5px 10px;
  margin: 3px;
  border: 0px;
  border-radius: 3px;
  box-shadow: 0px 1px 3px #c0c0c0;
}

.assessment-list button.edit {
  background: var(--yellow-dark);
}
.assessment-list button.edit:hover {
  background: var(--yellow-light);
}

.assessment-list button.assign {
  background: var(--orange-dark);
}

.assessment-list button.assign:hover {
  background: var(--orange-light);
}

.assessment-list button.results {
  background: var(--success-med);
}

.assessment-list button.results:hover {
  background: var(--success-light);
}

.assessment-list tr.even {
  background: var(--primary-light);
}

.assessment-list tr.odd {
  background: #eee;
}

.assessment-list tr td {
  border: 0px;
}

/* assignment */

.assignment-confirm {
  width: 80%;
  margin: 30px auto;
  text-align: center;
  padding: 20px;
  font-size: 120%;
  background: #fcfcfc;
}

/* ASSESSMENT QUESTION EDIT */
.question {
  padding: 10px;
  border: 1px #ccc;
  margin: 10px;
}

.question .edit {
  background: var(--primary-color);
  border: 0px;
  color: #fff;
  font-size: 80%;
  padding: 5px;
  min-width: 50px;
  margin: 0px 10px;
  border-radius: 2px;
}

.question h4 {
  background: var(--primary-light);
  padding-left: 10px;
  line-height: 250%;
}

.question .description {
  font-style: italic;
  font-size: 95%;
  line-height: 130%;
  padding: 15px;
}

.question-inner {
  padding: 10px;
}

.question-inner h3 {
  text-align: center;
  color: var(--primary-color);
}

.question-inner h4 {
  text-align: center;
}

.question-meta {
  background: var(--primary-light);
  border-left: 15px solid var(--primary-color);
}

.options {
  padding: 10px;
}

.options > div {
  margin: 10px;
  padding: 10px;
  background: var(--primary-light);
  border-radius: 5px;
  display: flex;
}

.options > div > span > i {
  margin-right: 10px;
  padding: 3px;
  border-radius: 3px;
}

.option-text {
  display: block;
}

.option-edit {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.textarea-edit {
  width: 70%;
}

/* CONTENT FRAME */

.frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.frame-content {
  width: 75%;
  float: right;
}

/* SIDEBAR */

.sidebar {
  width: 20%;
  float: left;
  background: var(--primary-light);
}

.sidebar h2 {
  background: var(--primary-color);
  color: #fff;
  line-height: 150%;
  padding: 7px;
}

.sidebar ul {
  list-style-type: none;
}

.sidebar ul li {
  padding: 5px;
  font-size: 120%;
}

.sidebar ul li a {
  display: block;
  background: var(--primary-light);
  padding: 10px;
  color: var(--primary-color);
}

.sidebar ul li a:hover {
  background: var(--primary-med);
}

.copyable {
  padding: 3px;
  width: 50%;
  max-height: 50px;
  font-size: 120%;
  font-family: inherit;
  word-break: break-all;
  resize: none;
}
