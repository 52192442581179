#dvmt2 {
  .no-padding {
    padding: 0;
  }

  .full-page {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .cover-page-top {
    height: 66%;
  }

  .cover-page-bottom {
    background: #314f6c;
    //box-shadow: 2px 10px 23px -2px rgba(0, 0, 0, 0.10);
    box-shadow: 1px 2px 4px #c0c0c0;
    //width: 100%;
    height: 34%;
    color: #fff;
    padding: 79px 60px 30px 60px;
  }

  .logo-container {
    position: absolute;
    top: 23px;
    left: 0;

    .title {
      position: absolute;
      top: 172px;
      left: 88px;
    }
  }

  .logo {
    width: 449px;
    height: 449px;
  }

  h1 {
    color: #314f6c;
    font-family: Catamaran;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 59px; /* 109.259% */
    letter-spacing: -0.54px;
    padding: 0;
    margin-bottom: 8px;
  }

  .orange-bar {
    width: 166px;
    height: 4px;
    flex-shrink: 0;
    background: #e87722;
  }

  h2 {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 59px; /* 368.75% */
    text-align: left;
  }

  .prepared-for-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #fff;
    padding-bottom: 26px;
    margin-bottom: 13px;
  }

  .prepared-for-text {
    color: #fff;
    font-family: Catamaran;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.16px;
  }

  .prepared-for-logo {
    width: 85px;
    height: 30px;
    flex-shrink: 0;
  }

  .prepared-date {
    color: #fff;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 116.667% */
    letter-spacing: 0.12px;
  }

  .copyright {
    color: #fff;
    text-align: right;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 116.667% */
    letter-spacing: 0.12px;
  }
}
