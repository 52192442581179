@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

@media print {
  @page {
    margin: 0;
  }
  body {
    print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .no-print {
    display: none !important;
  }

  .pdfSections {
    break-after: page !important;
    transform: scale(1.35);
    margin-top: 1.45in !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  /* Intended to prevent a page break after the last .pdfSections -- doesn't seem to work?*/
  .pdfSections:last-of-type {
    break-after: avoid !important;
  }
}

#dvmt2 {
  font-family: 'Noto Sans';
  font-size: 12pt;
  color: #111111;
  width: 612px;
}

#dvmt2.reportContainer,
#dvmt2 #downloadReport.reportContainer {
  background-color: transparent !important;
}

#dvmt2 .pdfSections {
  width: 612px !important;
  max-width: 612px !important;
  //margin: 20px auto;
  padding: 30px 60px;
  height: 792px !important;
  position: relative;
}

.dvmt2-padding {
  //padding: 30px 60px;
}

.dvmt2-margin {
  margin: 20px auto;
}

#dvmt2 .box-shadow-no-export {
  box-shadow: 1px 2px 4px #c0c0c0;

  @media print {
    box-shadow: none;
  }
}

#dvmt2 .header {
  width: 100%;
  background: none !important;
  height: 38px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#dvmt2 .header .logo {
  width: 75px;
  height: 26px;
  flex-shrink: 0;
}

#dvmt2 .header span {
  font-size: 11pt;
}

#dvmt2 .footer {
  position: absolute;
  bottom: 16px;
  width: 100%;

  padding-right: 104px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;

  span {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 360% */
    letter-spacing: 0.1px;

    .footer-text-bar {
      color: #e87722;
    }
  }

  .pageNum {
    display: flex;
    align-items: center;

    img {
      padding-left: 9px;
    }
  }
}

#dvmt2 .text-block {
  border-top: 1px #e87722 solid;
  padding-top: 11px;
  //padding-bottom: 24px;
  //margin-bottom: 5px;
  color: #111111;
  text-align: left;
  font-size: 12pt;
  line-height: 22pt;
}

#dvmt2 .text-block h4 {
  color: #314f6c;
  font-family: Catamaran;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 109.091% */
  letter-spacing: -0.22px;
  //border-left: #E87722;
  //border-left-width: 4px;
  //border-left-style: solid;
  //padding-left: 8px;
  margin-bottom: 11px;
  display: flex;

  .title-bar {
    display: inline-block;
    width: 4px;
    height: 24px;
    margin-right: 8px;
    background-color: #e87722;
  }
}

#dvmt2 .text-block h5 {
  color: #3d3d3d;
  font-family: Catamaran;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
  padding-left: 8px;
}

#dvmt2 .text-block p {
  color: #4d4c4c;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  letter-spacing: 0.12px;
}

#dvmt2 .text-block .plus-icon {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 8px;
}

.text-block p + .plus-icon {
  padding-top: 8px;
}

#dvmt2 .table-of-contents {
  .toc-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .col-left {
    padding-top: 6px;
  }

  .col-right {
    width: 100%;
    padding-left: 8px;
  }

  .toc-row-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #3d3d3d;
  }

  h5 {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
    letter-spacing: 0.12px;
    padding-left: 0px;
  }

  p {
    color: #4d4c4c;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.1px;
    width: 85%;
  }
}
