#dvmt2 .core-competencies-container {
  h6 {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
    letter-spacing: 0.12px;
    //margin-bottom: 4px;
  }

  p {
    margin-bottom: 11px;
  }

  .core-competency-inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 4px;
  }

  .core-competency-blurb {
    width: 38%;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #bfd5d9;
    background: #f3f6f9;

    p {
      color: #4d4c4c;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 145.455% */
      letter-spacing: 0.1px;
      margin: 15px;
    }
  }

  .core-competency-stack {
    //background-color: lightyellow;
    width: 59%;
  }

  .core-competency-chip {
    border-radius: 5px;
    border: 1px solid #bfd5d9;
    background: #f3f6f9;
    display: flex;
    //justify-content: space-between;
    margin-bottom: 10px;
  }

  .cc-color-block {
    width: 4px;
    border-radius: 4px 0 0 4px;
  }

  .core-competency-chip-inner {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cc-icon-container {
    width: 48px;
    text-align: center;
  }

  .cc-icon {
    height: auto;
    width: auto;
    max-width: 32px;
    max-height: 32px;
    vertical-align: sub;
  }

  .cc-label {
    margin-left: 16px;
    width: 100%;
    h5 {
      color: #3d3d3d;
      font-family: Catamaran;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.14px;
      padding: 0;
    }

    h6 {
      color: #4d4c4c;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.14px;
    }
  }

  .cc-donut-contain {
    width: 80px;
    height: 60px;
    //background-color: lightyellow;
  }
}
