#dvmt2 .building-on-results {
  p {
    color: #4d4c4c;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    letter-spacing: 0.12px;
    //margin-bottom: 0;
  }

  h6 {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
    margin-top: 18px;
  }

  .horizontal-line {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: #ccc;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }
}
