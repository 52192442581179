#dvmt2 .dimension-behavior-section {
  margin-top: 8px;
  justify-content: space-between;

  .category-name {
    display: flex;
    border-bottom: 2px solid #bfd5d9;
    margin-bottom: 0 !important;

    h4 {
      width: 50%;
      color: #3d3d3d;
      font-family: Catamaran;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.18px;
      margin-bottom: 0 !important;
    }

    p {
      width: 50%;
      color: #3d3d3d;
      font-family: Catamaran;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 200% */
      letter-spacing: 0.1px;
      margin-bottom: 0 !important;
      margin-left: 21px;
    }
  }

  .dimension-color-bars {
    display: flex;

    .dimension-color-bar {
      height: 4px;
      width: 50%;
      margin-bottom: 4px;
    }
  }

  .dimension-behavior-row {
    display: flex;
    align-items: center;

    margin-bottom: 9px;

    .col-left {
      width: 50%;
      display: flex;
      //justify-content: center;
      align-items: center;
    }

    .col-right {
      width: 50%;
    }

    img {
      width: 46px;
      height: 46px;
    }

    h5 {
      color: #3d3d3d;
      font-family: Catamaran;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.14px;
    }

    ul {
      width: 50%;
      list-style-type: disc;
      padding-left: 20px;
    }

    li {
      color: #4d4c4c;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px; /* 130% */
      letter-spacing: 0.1px;
    }
  }
}
