html,
body {
  margin: 0px !important;
  padding: 0px !important;
}

.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background: #ffffff;
  margin-top: -130px;
  height: 100vh;
  max-width: 1080px;

  .image,
  .content {
    height: 100vh;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ecf6fc;
    height: 130vh;
    width: 50%;
    margin-left: -20px;

    .content-inner {
      width: 70%;
      // height: 50%;
      h1 {
        color: #00629b;
        font-size: 90px;
        line-height: 20px;
        font-weight: 700;
        font-family: 'Noto Sans', arial, sans-serif;
        display: block;
      }

      h2 {
        color: #202e43;
        font-size: 34px;
        line-height: 30px;
        font-weight: 700;
        margin-top: 30px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
        margin: 20px;
        color: #111111;
      }

      a {
        color: #111111;
        text-decoration: underline;
      }
    }
  }

  .image {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    img {
      height: 65%;
    }
  }
}
