:root {
  --purple: #5d2a5f;
  --blue: #45aae1;
}

.search-table-contain {
  width: 100%;
}

.search-table {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  border-collapse: collapse;
  border: 1px solid #cccccc;
}

.search-table thead {
  /* background: #5D2A5F; */
  background: var(--purple);
  color: #fff;
  text-align: left;
}

.search-table tbody tr td {
  white-space: nowrap;
}
.search-table tbody tr td:last-child {
  width: 100%;
}

.search-table thead tr th,
.search-table tbody tr td {
  padding: 18px;
}

.pager {
  float: right;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.pager li {
  margin: 0px;
  padding: 5px;
  color: #202e43;
  font-size: 16pt;
}

.pager .active {
  background: none !important;
  font-weight: bold;
}

.search-table tbody tr {
  border-bottom: 1px solid #cccccc;
}

.search-table tbody tr:last-child {
  border-bottom: 0px;
}

.icon-labeled {
  font-size: 10pt;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.icon-labeled span {
  margin-left: 10px;
}

.icon-red {
  color: #ab0800;
}

.icon-green {
  color: #008b57;
}

.icon-blue {
  color: #45aae1;
}

.search-box {
  position: absolute;
  top: 10px;
  right: 500px;
  width: 400px;
  height: 60px;
  border-radius: 8px;
  border: #c0c0c0 2px solid;
  background: #fff;
}

.search-table-contain .filter-input {
  position: absolute;
  top: -30px;
  left: 600px;
  width: 400px;
  height: 40px;
  border-radius: 8px;
  border: #c0c0c0 2px solid;
  background: #fff;
  color: #c0c0c0;
  padding: 10px;
  font-size: 12pt;
}

.search-table-contain .clear-filter {
  display: none;
}

.search-table-contain a {
  color: #0d0d0d;
}

.logo {
  width: 200px;
}

#maincontent
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > div:nth-child(1)
  > div.col-sm-5.col-sm-push-4
  > select {
  display: none;
}

#maincontent
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > div:nth-child(1)
  > div.col-sm-4.col-sm-pull-4.text-center.text-muted.record-count
  > span {
  font-size: 16pt;
  font-weight: bold;
}

.search-table-contain .sortable {
  margin-right: 15px !important;
}

.pager .btn-prev-page,
.pager .btn-next-page {
  font-weight: bolder;
  color: var(--blue);
}
