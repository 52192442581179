#dvmt2 .dimension-section {
  margin-top: 24px;

  .dimension-name {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.18px;
    border-bottom: 2px solid #bfd5d9;
    margin-bottom: 0 !important;
  }

  .competency-name {
    color: #3d3d3d;
    font-family: Catamaran;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    padding-left: 0px;
  }

  .dimension-section-competencies {
    display: flex;

    .competency {
      width: 50%;

      .competency-color-bar {
        height: 4px;
      }

      .competency-layout {
        display: flex;
        align-items: flex-start;
        padding-top: 10px;
      }

      .competency-icon {
        padding-right: 8px;
      }

      .competency-name {
        color: #3d3d3d;
        font-family: Catamaran;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.14px;
      }

      .sub-competency-name {
        color: #4d4c4c;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
        letter-spacing: 0.12px;
      }
    }
  }
}
